@font-face {
  font-family: Questrian;
  src: local(Questrian), url("Questrian.7405cefc.otf") format("truetype");
}

* {
  box-sizing: border-box;
}

html {
  --bg: #efefef;
  --fg: #1e1e1e;
  --gray: #555;
  --light-gray: #ccc;
  --shadow: #e6e6e6;
  --success: #5ace84;
  --primary: #ff585d;
  --secondary: #174848;
  background: -webkit-radial-gradient(top left, #0c7073, #05161a 20%);
  background: radial-gradient(at 0 0, #0c7073, #05161a 20%);
  background-color: var(--bg);
  color: var(--fg);
  height: 100%;
  font-family: Questrian;
  font-size: calc(.9em + .5vw);
  line-height: 1.3;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 1em;
}

main {
  max-width: 26em;
  margin: 0 auto;
}

h1 {
  text-align: center;
  background-image: url("logo-black.54439fde.svg");
  background-position: 50% 1em;
  background-repeat: no-repeat;
  background-size: auto 1.5em;
  margin-top: 0;
  padding: 3.5em 0 0;
  font-size: 1.5em;
}

h3 {
  text-align: center;
  margin-top: 0;
}

h2 {
  text-align: center;
}

table {
  margin-top: 1em;
}

.status {
  text-align: center;
  font-family: monospace;
  font-size: small;
}

.please-wait .change {
  pointer-events: none;
}

a, .link {
  color: var(--primary);
  text-decoration: none;
}

a:hover, a:focus, .link:hover, .link:focus {
  text-decoration: underline;
}

a:active, .link:active {
  color: var(--secondary);
}

button, input {
  font: inherit;
  outline: none;
}

button {
  color: #d8e2e9;
  cursor: pointer;
  background-color: #174848;
  border: none;
  border-radius: 5px;
  padding: .3em .75em;
  font-size: 20px;
  transition: transform 30ms;
}

button:hover {
  box-shadow: 0 0 15px #30ff7a;
}

@media (prefers-color-scheme: dark) {
  html {
    --bg: #1e1e1e;
    --fg: #efefef;
    --gray: #aaa;
    --shadow: #2a2a2a;
    --light-gray: #444;
  }

  h1 {
    background-image: url("logo-white.605d2742.svg");
  }

  input:focus {
    box-shadow: inset 0 0 10em #ffffff05;
  }
}

#coin {
  transform-style: preserve-3d;
  width: 375px;
  height: 375px;
  margin: 2em auto 0;
}

#coin img {
  width: 375px;
}

.heads {
  transform: rotateY(180deg);
}

.tails, .heads {
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
}

.center-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.centered-container {
  justify-content: center;
  align-items: center;
  padding: 3em 0 0;
  display: flex;
}

.play-button {
  width: 100px;
  height: 50px;
  margin: 0 10px;
  font-size: 22px;
}

@keyframes flip-heads {
  100% {
    transform: rotateY(540deg);
  }
}

@keyframes flip-tails {
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes flip {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(2160deg);
  }
}

.confetti {
  width: 10px;
  height: 10px;
  position: fixed;
  top: -10px;
}

@keyframes fall {
  0% {
    transform: translateY(0)rotate(0);
  }

  100% {
    transform: translateY(100vh)rotate(360deg);
  }
}

.button-container {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(50px, auto);
  justify-content: center;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
  padding: 2.75em 0 0;
  display: grid;
}

.btn, .btn-option {
  cursor: pointer;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  margin: 4px 2px;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px #093a3a;
}

.button-container :nth-last-child(-n+2) {
  grid-column: span 2;
}

@media (width >= 600px) {
  .button-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: ". btn1 btn2 ."
                         ". btn3 btn4 .";
  }
}

.button-container > button:nth-child(9), .button-container > button:nth-child(10) {
  margin-top: 30px;
}

.highlighted {
  border: 1px solid #30ff7a;
  box-shadow: 0 0 15px #30ff7a;
}

.logo {
  border-radius: 20px;
  width: 75px;
  margin-top: -10px;
}
/*# sourceMappingURL=index.774d2b9d.css.map */
