/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'); */

@font-face {
  font-family: "Questrian";
  src: local("Questrian"), url(./fonts/Questrian.otf) format("truetype");
}

* {
  box-sizing: border-box;
}

html {
  --bg: #efefef;
  --fg: #1e1e1e;
  --gray: #555;
  --light-gray: #ccc;
  --shadow: #e6e6e6;
  --success: rgb(90, 206, 132);
  --primary: #FF585D;
  /* --secondary: #0072CE; */
  --secondary: #174848;
  
  height: 100%;
  /* background: linear-gradient(171deg, #0C7073, #05161A 25%) ; */
  background: -webkit-radial-gradient(top left, #0C7073, #05161A 20%);
  background: radial-gradient(at top left, #0C7073, #05161A 20%);

  background-color: var(--bg);
  color: var(--fg);
  font-size: calc(0.9em + 0.5vw);
  line-height: 1.3;
  overflow-x: hidden; /* Prevents horizontal scroll */

  font-family: 'Questrian';
}

body {
  margin: 0;
  padding: 1em;
}

main {
  margin: 0 auto;
  max-width: 26em;
}

h1 {
  background-image: url(./logo-black.svg);
  background-position: center 1em;
  background-repeat: no-repeat;
  background-size: auto 1.5em;
  margin-top: 0;
  padding: 3.5em 0 0;
  text-align: center;
  font-size: 1.5em;
}

h3 {
 
  margin-top: 0;
  text-align: center;
  /* font-size: 1.5em; */
}

h2 {
  text-align: center;
}

table {
  margin-top: 1em;
}

.status {
  font-size: small;
  font-family: monospace;
  text-align: center;
}

.please-wait .change {
  pointer-events: none;
}


a,
.link {
  color: var(--primary);
  text-decoration: none;
}

a:hover,
a:focus,
.link:hover,
.link:focus {
  text-decoration: underline;
}

a:active,
.link:active {
  color: var(--secondary);
}

button,
input {
  font: inherit;
  outline: none;
}

button {
  background-color: #174848;
  color: #D8E2E9;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 0.3em 0.75em;
  transition: transform 30ms;
  font-size: 20px;
}


button:hover {
  /* box-shadow: 0 0 10em rgba(255, 255, 255, 0.2) inset; */
  box-shadow: 0 0 15px rgb(48, 255, 122);
}

@media (prefers-color-scheme: dark) {
  html {
    --bg: #1e1e1e;
    --fg: #efefef;
    --gray: #aaa;
    --shadow: #2a2a2a;
    --light-gray: #444;
  }

  h1 {
    background-image: url(./logo-white.svg);
  }

  input:focus {
    box-shadow: 0 0 10em rgba(255, 255, 255, 0.02) inset;
  }
}

/* coin flip animations: https://rezabaharvand.dev/blog/coin-flip-javascript */

#coin {
  height: 375px;
  width: 375px;
  margin: 2em auto 0 auto;
  transform-style: preserve-3d;
}

#coin img {
  width: 375px;
}

.heads {
  transform: rotateY(180deg);
}

.tails,
.heads {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.center-wrapper {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.centered-container {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  padding: 3.0em 0 0;
}

.play-button {
  margin: 0 10px;
  width: 100px;
  height: 50px;
  font-size: 22px;
}

@keyframes flip-heads {
  100% {
    transform: rotateY(540deg);
  }
}

@keyframes flip-tails {
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes flip {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(2160deg);
  }
}

.confetti {
  position: fixed;
  width: 10px;
  height: 10px;
  top: -10px; /* Start above the screen */
  /* Basic styling for confetti */
}

@keyframes fall {
  0% { transform: translateY(0) rotate(0deg); }
  100% { transform: translateY(100vh) rotate(360deg); }
}

.button-container {
  padding: 2.75em 0 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for the first two rows */
  grid-auto-rows: minmax(50px, auto); /* Adjust the row height as needed */
  gap: 10px; /* Space between buttons */
  justify-content: center; /* This will center the entire grid */
  max-width: 600px; /* Adjust based on the maximum width you want */
  margin: 0 auto; /* Center the grid container */
}

.btn {
  /* Style your buttons */
  padding: 10px;
  border: 1px solid #ccc; /* Example styling */
  cursor: pointer;

  border: none;
  padding: 10px 20px; /* Top/bottom padding and left/right padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px; /* This gives the rounded corners */
  box-shadow: 0 4px #093A3A; /* Simple shadow effect */
}

.btn-option {
  /* Style your buttons */
  padding: 10px;
  border: 1px solid #ccc; /* Example styling */
  cursor: pointer;

  border: none;
  padding: 10px 20px; /* Top/bottom padding and left/right padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px; /* This gives the rounded corners */
  box-shadow: 0 4px #093A3A; /* Simple shadow effect */
}


.button-container :nth-last-child(-n+2) {
  grid-column: span 2;
}

/* Specific styling for the last row to only have 2 buttons */
@media (min-width: 600px) { /* Adjust this based on your max-width and button sizes */
  .button-container {
    grid-template-columns: repeat(4, 1fr); /* Reaffirms the grid for wider screens */
    grid-template-areas:
      " . btn1 btn2 . "
      " . btn3 btn4 . "; /* Example to center the last two buttons, not necessary unless specific positioning is required */
  }
  
  /* You could target the last two buttons specifically if needed for unique styling or positioning */
}
.button-container > button:nth-child(9) {
  margin-top: 30px; /* Adjust this value to control the space between the second and third rows */
}
.button-container > button:nth-child(10) {
  margin-top: 30px; /* Adjust this value to control the space between the second and third rows */
}

.highlighted {
  border: 1px solid rgb(48, 255, 122);
  box-shadow: 0 0 15px rgb(48, 255, 122);
}

.logo {
  border-radius: 20px;
  /* padding: 5px; */
  margin-top: -10px;
  width: 75px;
}
